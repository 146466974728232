import { Outlet } from 'react-router-dom';
import Sidebar from '../../components/common/Sidebar/Sidebar';
import styles from './MainLayout.module.scss';

const MainLayout = () => {
    return (
        <div className={styles.mainWrapper}>
            <Sidebar />

            <div className={styles.contentWrapper}>
                <Outlet />
            </div>
        </div>
    );
};

export default MainLayout;
