/* eslint-disable jsx-a11y/anchor-is-valid */
import { useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import styles from './Sidebar.module.scss';
import { useNavigate } from 'react-router-dom';

const Sidebar = () => {
    const isVisible = true;
    const navigate = useNavigate();

    const hideSidebar = useCallback(() => {}, []);

    const handleLogout = useCallback(() => {
        localStorage.removeItem('token');
        navigate('/login');
    }, [navigate]);

    // useEffect(() => {
    //     hideSidebar();
    // }, [hideSidebar, pathname]);

    return (
        <>
            <div className={[styles.sidebar, isVisible ? styles.show : ''].join(' ')}>
                <div className={styles.sidebarBrand}>
                    <img src="/logos/logo.svg" alt="" />
                </div>

                <button className={styles.sidebarClose} onClick={hideSidebar}>
                    <i className="fa fa-close"></i>
                </button>

                <div className={styles.sidebarNav}>
                    <div>
                        <h6 className={styles.navListHeading}>Manage</h6>

                        <ul className={styles.navList}>
                            <li>
                                <NavLink to="/" className={styles.sidebarLink}>
                                    Dashboard
                                </NavLink>
                            </li>
                        </ul>
                    </div>

                    <div className="mt-4">
                        <h6 className={styles.navListHeading}>Netim</h6>

                        <ul className={styles.navList}>
                            <li>
                                <NavLink to="/netim/regions" className={styles.sidebarLink}>
                                    Regions
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/netim/poles" className={styles.sidebarLink}>
                                    Poles
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/netim/cameras" className={styles.sidebarLink}>
                                    Cameras
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/netim/users" className={styles.sidebarLink}>
                                    Users
                                </NavLink>
                            </li>
                        </ul>
                    </div>

                    <div className="mt-4">
                        <h6 className={styles.navListHeading}>Neom</h6>

                        <ul className={styles.navList}>
                            <li>
                                <NavLink to="/neom/areas" className={styles.sidebarLink}>
                                    Areas
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/neom/subareas" className={styles.sidebarLink}>
                                    Sub Areas
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/neom/cameras" className={styles.sidebarLink}>
                                    Cameras
                                </NavLink>
                            </li>
                        </ul>
                    </div>

                    <div className="mt-auto">
                        <h6 className={styles.navListHeading}>Account</h6>

                        <ul className={styles.navList}>
                            <li>
                                <a className={styles.sidebarLink} onClick={handleLogout}>
                                    Sign out
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Sidebar;
