import React from 'react';

import { Navigate, useLocation } from 'react-router-dom';

function AuthGuard({ children }) {
    const location = useLocation();

    const token = localStorage.getItem('token');

    if (!token) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return children;
}

export default AuthGuard;
