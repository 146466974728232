import { Suspense, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import lazyWithPreload from './config/lazy-with-preloading';
import FallbackLoader from './components/common/FallbackLoader/FallbackLoader';
import MainLayout from './layouts/MainLayout/MainLayout';
import AuthGuard from './components/guards/AuthGuard/AuthGuard';

const Dashboard = lazyWithPreload(() => import('./pages/manage/Dashboard/Dashboard'));
const NetimRegionList = lazyWithPreload(() => import('./pages/netim/NetimRegionList/NetimRegionList'));
const NetimRegionAdd = lazyWithPreload(() => import('./pages/netim/NetimRegionAdd/NetimRegionAdd'));
const NetimRegionEdit = lazyWithPreload(() => import('./pages/netim/NetimRegionEdit/NetimRegionEdit'));
const NetimPoleList = lazyWithPreload(() => import('./pages/netim/NetimPoleList/NetimPoleList'));
const NetimCameraList = lazyWithPreload(() => import('./pages/netim/NetimCameraList/NetimCameraList'));
const NetimUserList = lazyWithPreload(() => import('./pages/netim/NetimUserList/NetimUserList'));
const NetimUserAdd = lazyWithPreload(() => import('./pages/netim/NetimUserAdd/NetimUserAdd'));
const NetimUserEdit = lazyWithPreload(() => import('./pages/netim/NetimUserEdit/NetimUserEdit'));
const NeomCameraAdd = lazyWithPreload(() => import('./pages/neom/NeomCameraAdd/NeomCameraAdd'));
const NeomCameraEdit = lazyWithPreload(() => import('./pages/neom/NeomCameraEdit/NeomCameraEdit'));
const NeomSubAreaAdd = lazyWithPreload(() => import('./pages/neom/NeomSubAreaAdd/NeomSubAreaAdd'));
const NeomSubAreaEdit = lazyWithPreload(() => import('./pages/neom/NeomSubAreaEdit/NeomSubAreaEdit'));
const NeomAreaList = lazyWithPreload(() => import('./pages/neom/NeomAreaList/NeomAreaList'));
const NeomSubAreaList = lazyWithPreload(() => import('./pages/neom/NeomSubAreaList/NeomSubAreaList'));
const NeomCameraList = lazyWithPreload(() => import('./pages/neom/NeomCameraList/NeomCameraList'));
const Login = lazyWithPreload(() => import('./pages/auth/Login/Login'));

const PageNotFound = lazyWithPreload(() => import('./pages/other/PageNotFound/PageNotFound'));

function AppRouter() {
    useEffect(() => {
        Dashboard.preload();
        NetimRegionList.preload();
        NetimRegionAdd.preload();
        NetimRegionEdit.preload();
        NetimPoleList.preload();
        NetimCameraList.preload();
        NetimUserList.preload();
        NetimUserAdd.preload();
        NetimUserEdit.preload();
        NeomCameraAdd.preload();
        NeomCameraEdit.preload();
        NeomSubAreaAdd.preload();
        NeomSubAreaEdit.preload();
        NeomAreaList.preload();
        NeomSubAreaList.preload();
        NeomCameraList.preload();
        Login.preload();
        PageNotFound.preload();
    }, []);

    return (
        <Suspense fallback={<FallbackLoader />}>
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/" element={<MainLayout />}>
                    <Route
                        index
                        element={
                            <AuthGuard>
                                <Dashboard />
                            </AuthGuard>
                        }
                    />
                    <Route
                        path="/netim/regions"
                        element={
                            <AuthGuard>
                                <NetimRegionList />
                            </AuthGuard>
                        }
                    />
                    <Route
                        path="/netim/regions/add"
                        element={
                            <AuthGuard>
                                <NetimRegionAdd />
                            </AuthGuard>
                        }
                    />
                    <Route
                        path="/netim/regions/:regionId/edit"
                        element={
                            <AuthGuard>
                                <NetimRegionEdit />
                            </AuthGuard>
                        }
                    />
                    <Route
                        path="/netim/poles"
                        element={
                            <AuthGuard>
                                <NetimPoleList />
                            </AuthGuard>
                        }
                    />
                    <Route
                        path="/netim/cameras"
                        element={
                            <AuthGuard>
                                <NetimCameraList />
                            </AuthGuard>
                        }
                    />
                    <Route
                        path="/netim/users"
                        element={
                            <AuthGuard>
                                <NetimUserList />
                            </AuthGuard>
                        }
                    />
                    <Route
                        path="/netim/users/add"
                        element={
                            <AuthGuard>
                                <NetimUserAdd />
                            </AuthGuard>
                        }
                    />
                    <Route
                        path="/netim/users/:userId/edit"
                        element={
                            <AuthGuard>
                                <NetimUserEdit />
                            </AuthGuard>
                        }
                    />
                    <Route
                        path="/:poleId/neom/subareas/add"
                        element={
                            <AuthGuard>
                                <NeomSubAreaAdd />
                            </AuthGuard>
                        }
                    />
                    <Route
                        path="/:cameraId/neom/cameras/add"
                        element={
                            <AuthGuard>
                                <NeomCameraAdd />
                            </AuthGuard>
                        }
                    />
                    <Route
                        path="/neom/areas"
                        element={
                            <AuthGuard>
                                <NeomAreaList />
                            </AuthGuard>
                        }
                    />
                    <Route
                        path="/neom/subareas"
                        element={
                            <AuthGuard>
                                <NeomSubAreaList />
                            </AuthGuard>
                        }
                    />
                    <Route
                        path="/neom/subareas/add"
                        element={
                            <AuthGuard>
                                <NeomSubAreaAdd />
                            </AuthGuard>
                        }
                    />
                    <Route
                        path="/neom/subareas/:subAreaId/edit"
                        element={
                            <AuthGuard>
                                <NeomSubAreaEdit />
                            </AuthGuard>
                        }
                    />
                    <Route
                        path="/neom/cameras"
                        element={
                            <AuthGuard>
                                <NeomCameraList />
                            </AuthGuard>
                        }
                    />
                    <Route
                        path="/neom/cameras/add"
                        element={
                            <AuthGuard>
                                <NeomCameraAdd />
                            </AuthGuard>
                        }
                    />
                    <Route
                        path="/neom/cameras/:cameraId/edit"
                        element={
                            <AuthGuard>
                                <NeomCameraEdit />
                            </AuthGuard>
                        }
                    />
                </Route>
                <Route path="/404" element={<PageNotFound />} />
                <Route path="*" element={<Navigate replace to="/404" />} />
            </Routes>
        </Suspense>
    );
}

export default AppRouter;
