import { Component } from 'react';
import styles from './ErrorBoundary.module.scss';

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    goBack() {
        window.location.href = '/';
    }

    componentDidCatch(error, errorInfo) {
        if (process.env.NODE_ENV === 'development') {
            console.log({ error: error, errorInfo: errorInfo });
        }
    }

    render() {
        if (this.state.hasError) {
            return (
                <div className={styles.errorUi}>
                    <h1 className={styles.heading}>Oops, something went wrong</h1>
                    <button className="btn btn-primary mt-4" onClick={this.goBack}>
                        Go Back Home
                    </button>
                </div>
            );
        }

        return this.props.children;
    }
}
export default ErrorBoundary;
