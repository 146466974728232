import { QueryClient, QueryClientProvider } from 'react-query';
import { ToastContainer } from 'react-toastify';
import AppRouter from './AppRouter';

import ErrorBoundary from './components/common/ErrorBoundary/ErrorBoundary';

// Create a client
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            cacheTime: 0,
        },
    },
});

function App() {
    return (
        <ErrorBoundary>
            <QueryClientProvider client={queryClient}>
                <AppRouter />
            </QueryClientProvider>
            <ToastContainer autoClose={3000} hideProgressBar={true} closeButton={false} />
        </ErrorBoundary>
    );
}

export default App;
